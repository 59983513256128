.box:not(.box-about) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
}

/* a bit less*/
.box.stake-page {
  margin-top: 0px;
  margin-bottom: 10px;
}

.box-bottom-spacer {
  visibility: hidden;
  height: 100px;
  display: block;
}

.box.small-margin-top {
  margin-top: 3rem !important;
}
  
.box .outerBox {
  min-width: 290px;
  min-height: 290px;
  border: 2px solid #000;
  margin: 20px;
  text-align: center;
  border-radius:10px;
  z-index:401;
  vertical-align: bottom;
  display: flex;
  flex-direction: column-reverse;
  background-color: white;
  text-decoration: none;

}

.box .outerBox.outerBoxPlay{
  flex-direction: column!important;
  width: 290px;
  height: 300px;
  border: none;
  margin: 20px;
  text-align: center;
  border-radius:10px;
  z-index:401;
  vertical-align: bottom;
  display: flex;
  flex-direction: column-reverse;
  background-color: white;
  text-decoration: none;
}

.box .outerBox :hover {
  text-decoration: none;
}

.outerBox .innerBox {
  min-width: 160px;
  height:50px;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  width: 100%;

  /*border-bottom: 2px solid #000;*/
  max-height: 50px;
  /*background-color: yellow;*/
  z-index:200;
}

.innerBox:not(.innerBoxPlay) {
  margin-bottom: 10px;
}

.outerBox .innerBoxPlay, .outerBox .innerBoxDouble {
  
  z-index:200;
  border: none;
  line-height: 1.1rem;
  border-radius: 0 0 8px 8px;
  height: 40px;
  /*margin-bottom: 2px;
  padding: 2px;*/
  padding-left: 3px;
  
}

.outerBox .innerBoxPlay {
  background-color: white;
  text-align: left;
}

.innerBoxPlay span {
  text-decoration: none!important;
  color: black;
}

/* On screens that are 603px or less, display collumns instead */
@media screen and (max-width: 1320px) {
  .box {
    margin-top: 80px;
    min-height: 700px;
  }
}

.mainBox1, .mainBox2, .mainBox3, .mainBox4, .mainBox5, .mainBox6, .mainBox7, .mainBox11, .playBox {  
  background-position: center 30%;
  background-size: 50%;
  background-repeat: no-repeat;
}

.mainBox8, .mainBox9, .mainBox10 {
  background-position: center 40%;
  background-size: 60%;
  background-repeat: no-repeat;
}

.mainBox1 {
  background-image: url('../assets/imgs/play.png') ;
  background-position: center 20%; /*shorter image*/
} 

.mainBox2 {
  background-image: url('../assets/imgs/invest.png') ;
}

.mainBox3 {
  background-image: url('../assets/imgs/trade.png') ;
} 

.mainBox4 {
  background-image: url('../assets/imgs/stake.png') ;
}

.mainBox5 {
  background-image: url('../assets/imgs/stakegaming.png') ;
}

.mainBox6 {
  background-image: url('../assets/imgs/Uniswap_Logo.svg.png') ;
}

.mainBox7 {
  background-image: url('../assets/imgs/Uniswap_Logo.svg.png') ;
}

.mainBox8 {
  background-image: url('../assets/imgs/Pirates2048.png') ;
}

.mainBox9 {
  background-image: url('../assets/imgs/SimRacer.png') ;
}

.mainBox10 {
  background-image: url('../assets/imgs/crypto-chaser-logo.png') ;
}

.mainBox11 {
  background-image: url('../assets/imgs/logo.bingx.png') ;
  background-position-y: center;
}

.playBox-inner-wrapper {
  border-radius: 10px;
  border: 2px solid #000;
  width: 100%;
  height:340px;
  margin: 0;
  padding: 0;
  display:inline-block;
}
 
.playBox-inner {
  position: relative;
  top: 0;
  left: 0;
  /*width: 290px;*/
  margin: 0 auto;
  height: 275px;
  border-radius: 8px 8px 10px 10px;
  border-bottom: none;
  /*border: 2px solid #000;*/
}

.playBox1-inner {
  background-size: 100%;
  background-image: url('../assets/imgs/Pirates_2048_400x400.png') ;
  background-position-y:-6px;
  /*image has central design too close from upper and bottom borders*/
}

.playBox3-inner {
  background-image: url('../assets/imgs/Soccer_400x400.png') ;
  background-size: 100%;
}

.playBox2-inner {
  background-image: url('../assets/imgs/Simthunder_400x400.png') ;
  background-size: 100%;
}

.playBox4-inner {
  background-image: url('../assets/imgs/crypto-chaser-logo.png') ;
  background-size: 100%;
}

.playBox5-inner {
  background-image: url('../assets/imgs/neeon-logo.png') ;
  background-size: 100%;
}

.innerBox span {
  top:35%;
  font-weight: bold;
}

.link-plain {
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #333;
  border-radius:5px;
  background-color: #f0b513;
  display: inline-block;
  width: 90%;
  height: 50px;
}

.disabledLink {
  text-decoration: line-through;
  font-size: 14px;
}

.wrapped-div {
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  color:#000;
  font-weight: bold;
}

.link-plain-play {
  color: black;
  font-family: 'RobotoMono',Arial,sans-serif;
  font-size: 22px;
  font-weight: bold;
}

.link-plain-invest, .link-plain-main {
  background-color: black;
  font-family: 'RobotoMono',Arial,sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: white;
  border-radius: 5px;
  border:1px solid #000;
  padding:3px;
}

.link-plain-invest:hover, .link-plain-main:hover, .link-plain-play:hover, .link-plain-play a:hover {
  background-color: #f0b513;
  text-decoration:none;
}

.link-plain-play:hover, .link-plain-play a:hover {
  color:white;
}

.mail-link {
  color: #f0b513;
  font-weight:bolder;
  font-family: 'RobotoMono',Arial,sans-serif;
  font-size: 16px;
  text-transform: uppercase;
}

.mail-link:hover {
  text-decoration:none;
  color: #f0b513;
  cursor: pointer;
}

.link-plain-invest a, .link-plain-main a{
  color: white;
  text-decoration:none;
}

.link-plain-invest a:hover, .link-plain-main a:hover{
  color: white;
  text-decoration:none;
}

.invest-span {
  color: #000000;
  font-size: 20px;
  font-family: 'RobotoMono',Arial,sans-serif;
  line-height: 1.3;
  font-weight: 300;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
}
  

.header_ggdap {
  font-family:  'RobotoMono',Arial,sans-serif;
  /*'PixelFont', Arial, sans-serif;*/
  width:100%;
  height: 80px;
  position:absolute;
  left:0;
  top: 0;
  vertical-align: top;
  text-align: center;
  background-color: #000;
  color: white;
  border-bottom:  2px solid #000;
  border-radius: 20px 20px 0 0;
}

.footer_ggdap {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 180px;
  background-color: #222;
  color: white;
  z-index: 1010;
  font-family: 'RobotoMono',Arial,sans-serif;
  font-size: 13px;
}

.header {
  position: fixed;
  width: 100%;
  height: 80px;
  z-index: 1010;
}

.header_span {
  position: absolute;
  top: 20%;
  left: 0;
  font-size: 1.8rem;
  font-weight: bold;
  z-index: 4;
  color: white;
  width: 100%;
  margin: 0 auto;
  /*font-family: 'PixelFont', Arial, sans-serif;*/
}

.header_span_subtitle {
  font-size: 2.5rem;
  font-weight: bold;
  z-index: 4;
  color: black;
  padding-top: 3.2rem;
  width: 100%;
  text-align: center;
  position: relative;
  top: 80px;
  font-family: 'PixelFont', Arial, sans-serif;
}

.header_ggdap_logo{
  display: block;
  width:110px;
  height: 45px;
  margin-top: 0.8rem;
  margin-left: 40px;
  background-image: url('../assets/imgs/gg_logo.png') ;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 10011!important;
  position: absolute;
}

.header_ggdap_logo .beta {
  vertical-align: middle;
  color: white;
  margin-left: 118px;
  font-weight: bold;
  position: relative;
  top: 22px;
}

.beta:hover {
 text-decoration: none;
 cursor: none;
}

.footer_ggdap .logo {
  min-width:182px;
  height: 75px;
  background-image: url('../assets/imgs/gg_logo_big.png') ;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.footer_ggdap .logo:hover {
  cursor: pointer;
}

.footer_container {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 30px;
  width:100%;
}

.telegram, .twitter, .medium, .discord {
  background-repeat: no-repeat;
  background-size: 40px 40px;
  background-position: center center;
  width: 40px;
  height: 40px;
  margin-left: 3px;
  margin-right: 3px;
}

.telegram {
  background-image: url('../assets/imgs/telegram.webp');
}

.twitter {
  background-image: url('../assets/imgs/twitter.webp');
}

.medium {
  background-image: url('../assets/imgs/medium.webp');
}

.discord {
  background-image: url('../assets/imgs/discord.png');
}

@media only screen and (max-width: 1600px) {
  .footer_container {
    font-size:12px;
  }

  .footer_bottom {
    font-size:13px;
    height: 50px;
  }
}

.wallet-span {
  font-weight: bold;
  display: inline;
  color: #f0b513;
  font-size: 11px;
  position: absolute;
  right: 90px;
  top:28px;
}

@media only screen and (max-width: 900px) {
  .wallet-span {
    right: 40px;
    top:58px;
  }
}

@media only screen and (max-width: 320px) {
  .wallet-span {
    left: 40px;
    top:58px;
    font-size: 9px;
  }
}

@media only screen and (max-width: 220px) {
  .wallet-span {
    left: 5px;
    top:58px;
    font-size: 8px;
  }
}

@media only screen and (max-width: 680px) {
  .header_span_subtitle {
    font-size: 2.3rem;
  }
}

@media only screen and (max-width: 600px) {
  .header_span_subtitle {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 550px) {
  .header_span {
    font-size: 1.2rem;
  }

  .header_span_subtitle {
    font-size: 1.2rem;
  }

  .footer_container {
    flex-direction: column;
    font-size: 10px;
    top:28px;
  }

  .contact_footer_column {
    text-align: center!important;
  }

  .footer_bottom {
    flex-direction: column;
    min-height: 170px;
  }

  .footer_ggdap {
    min-height: 460px;
    bottom: 0;
  }

  .footer_ggdap.stake-footer {
    position: absolute;
  }

  .footer_span {
    margin: 0 10px 5px 10px;
  }

  .footer_span.span_empty {
    display:none;
  }

  .header_ggdap_logo {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 280px) {
  .footer_bottom {
    font-size: 11px;
  }
}

@media only screen and (max-width: 220px) {
  .footer_ggdap {
    min-height: 530px;
    font-size: 9px;
    line-height: 18px;
  }

  .footer_bottom {
    flex-direction: column;
    min-height: 180px;
    font-size: 10px;
    margin: 0 auto;
  }
}

.contact_footer_column {
  text-align: justify;
  margin: 0 auto;
}

.contact_footer_column_logos {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .footer_container{
    font-size:11px;
  }
  .telegram, .twitter, .medium {
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: center center;
  }
}

.footer_bottom {
  display: flex;
  background-color: black;
  color: white;
  position: absolute;
  bottom:0;
  left:0;
  width:100%;
  height:45px;
  text-align:center;
}

.footer_bottom span {
  margin: 8px auto;
  font-family: 'RobotoMono',Arial,sans-serif; 
}

.footer_bottom a {
  color:white;
}

.footer_bottom a:hover {
  color:white;
  text-decoration:none;
}

.center-text {
  text-align: center;
}

.footer_span {
  margin: 0 15px 15px 15px;
}

.my-nav-link{
  color: #333;
  text-decoration: none;
  margin: 15px 10px 5px 10px;
  background-color: #f0b513;
  padding: 5px 10px;
  border-radius: 5px;
  border:1px solid #333;
  cursor: pointer;
  font-weight: bold;
  display: block;

}

.my-nav-link:hover {
  color:white;
  text-decoration: none;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.item {
  margin: 10px;
}

.nopadding {
  padding: 0 !important;
  margin: 2px !important;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
  color: #000;
}

.btn-round {
  border-radius: 5px;
  min-width:200px;
  min-height:80px;
  border: 1px solid black;
  padding: 5px;
  background-color: gainsboro;
  color: white;
  text-decoration: none;
}

.main_menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  min-height: 80px;
  z-index: 1;
}

.main_menu_container {
  position: relative;
  top:0;
  float: right;
  z-index: 400;
  color:white;
  background-color: #000;
  width:30%;
  display: flex;
}

.stake_container {
  width: 100%;
  margin: 50px auto;
  display: flex;
  height: 100%;
  overflow-y:auto;
}

.center_table_container {
  width: 75%;
}

.stake_container td {
  width: 50%;
}

.tokenomics {
  /*background-image: url('../assets/imgs/chart.png') ;
  background-position: center center;
  background-size: 700px 400px;
  background-repeat: no-repeat;
  width: 100%;*/
  
  width:25%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  padding-top: 80px;
  margin-right:50px;
}

.connect {
  margin: 0 auto;
  min-width: 30%;
}

.center_table {
  /*position: absolute;*/
  left: 22%;
  margin: 0 auto;
  margin-top: 100px;
  color:black;
  font-family: 'RobotoMono',Arial,sans-serif; 
  font-size: 14px; 
  font-weight: 300;
  text-align: justify;
  height: 50%;
  max-width: 80%;
}

table { border: none; border-collapse: collapse; }
table td { border-left: 1px solid #000; /*padding-left: 20px;*/ padding-right: 20px;}
table td:first-child { border-left: none; }
table tr>td {
  padding-bottom: 0.6em;
}

table td.padding-left10px { padding-left: 10px;}

.rewards-div {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1024px) {
  .tokenomics {
    margin-right:200px;
    margin-top:10px;
  }
}

@media screen and (max-width: 1024px) {
  .stake_container {
    flex-direction: column;
  }
  .tokenomics {
    padding-top: 0px;
    margin: 0 auto;
    width:100%;
  }

  .connect {
    padding-bottom: 10px;
  }

  .center_table_container {
    width: 99%;
  }

  .add_stake {
    top: 0;
  }
}

@media screen and (min-width: 1200px) {
  .center_table {
    left: 25%;
  }
}

@media screen and (min-width: 1400px) {
  .center_table {
    left: 28%;
  }
}

@media screen and (min-width: 1600px) {
  .center_table {
    left: 30%;
  }
}

@media screen and (min-width: 2000px) {
  .center_table {
    left: 35%;
  }
}

@media screen and (max-width: 520px) {
  .center_table {
    font-size: 11px;
  }
}

@media screen and (max-width: 580px) {
  .center_table {
    font-size: 12px;
    left: 12%;
  }

  table td { border-left: 1px solid #000; padding-left: 10px; padding-right: 10px;}
}

@media screen and (max-width: 480px) {
  .center_table {
    font-size: 10px;
    left: 5%;
    line-height:0.8rem;
  }

  table td { border-left: 1px solid #000; padding-left: 5px; padding-right: 5px;}
}

@media screen and (max-width: 380px) {
  .center_table {
    font-size: 9px;
    left: 2%;
  }

  .connect {
    font-size:12px;
  }

  table td { border-left: 1px solid #000; padding-left: 2.5px; padding-right: 2.5px;}
}

@media screen and (max-width: 400px) {
  .connect-btn, .connect-btn-unstake, .btn-rewards, .btn-stake, .btn-restake, .project-info-btn {
    font-size: 13px!important;
  }
}

@media screen and (max-width: 220px) {
  .connect {
    font-size: 10px;
    max-width: 90%!important;
  }

  .connect-btn, .connect-btn-unstake, .btn-rewards, .btn-stake, .btn-restake, .project-info-btn {
    font-size: 10px!important;
  }

  .add_stake {
    position: absolute;
    left: 0!important;
  }
}

.connect-btn, .connect-btn-unstake, .btn-rewards, .btn-stake, .btn-restake, .project-info-btn {
  border-radius: 5px;
  margin-top:15px;
}

.connect-btn-unstake, .project-info-btn {
  background-color: #f0b513!important;
  vertical-align: middle;
  
  margin: 0 auto;
  border:1px solid #000!important;
}

.connect-btn-unstake {
  margin-top:5rem;
  margin-bottom: 25px;
}


@media screen and (max-width: 600px) {
  .connect-btn-unstake {
    margin-top:0;
  }
}

.connect-wallet {
  position: absolute;
  top:100px;
  left:100px;
  min-width: 120px;
  font-weight: bold;
}

.connect-btn-unstake:hover {
  cursor: pointer;
  color: black;
}

.btn-disabled:hover {
  cursor: progress!important;
  color: white!important;
}

.btn-rewards {
  background-color: greenyellow!important;
  color: #000!important;
}

.btn-restake {
  background-color: yellowgreen!important;
  color: #000!important;
  margin-top: 0!important;
}

.btn-stake {
  border:1px solid #000 !important;
}

.btn-stake:hover, .btn-rewards:hover, .btn-restake:hover {
  cursor: pointer;
  font-weight: bolder;
}

.btn-disabled:hover {
  font-weight: normal;
}

.available_rewards {
  font-size: 10px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  vertical-align: text-top;
}

.btn-full-width {
  width: 100%;
}

.stake_input {
  min-width:100px;
  text-align:right;
  
}

.add_stake {
  width:100%;
  padding:10px;
  margin: 0;
  position: relative;
  top: 30%;
  display:flex;
  text-align:center;
  flex-direction:column;
  background-color:white;
  border:1px solid #000;
  border-radius:5px;
  font-family: 'RobotoMono',Arial,sans-serif;
  z-index: 1001;
}

@media screen and (max-width: 600px) {
  .add_stake {
    margin: 0 auto;
    margin-top: 3rem;
    top:0;
  }
}

@media screen and (min-width: 1025px) {
  .add_stake {
    min-width: 292px;
  }
}

.connect-metamask {
  width: 350px;
  margin: 0 auto;
  background-color: white;
  border-radius: 5px;
  border: 2px solid #000;
  font-weight: normal;
}

.error_div {
  color: red!important;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 350px;
  font-weight: bold;
}


.btn-min-height40 {
  min-height: 40px;
  color: white;
}

.navigation {
  width:100%;
  height: 50px;
  color: white;
  z-index: 1100;
  position: absolute;
  /*right: 60px;*/
  top:20px;

}

.navigation ul {
  /*display: flex;
  flex-wrap: wrap;
  float: right;
  margin: 10 0 px;
  padding: 0 25px;*/
  color: white;
  z-index: 180;

  height: 200px;
  width: 200px;
  margin-top: 40px;
  margin-right: 150px;
  position: fixed;
  right: -140px;
  
}

.navigation ul li {
  list-style-type: none;
  line-height: 2.4rem;
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  min-width: 100px;
  text-align: center;
  margin: 0 auto;
}

.invisible {
  display: none;
}

.inline {
  display: inline;
}

.hamburger {
  width:2rem;
  height:2rem;
  display:flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index:1000;
  position: absolute;
  right: 20px;
}

.hamburger:hover {
  cursor:pointer;
}

.burger {
  width:2rem;
  height: 0.25rem;
  border-radius:10px;
  background-color:white;
  transform-origin:1px;
  transition: all 0.3s linear;
}

.error-message {
  color: #cc0033;
  display: inline-block;
  font-size:10px;
}

.games_container {
  width:80%;
  margin: 0 10px 0 10px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 6px;
  padding: 5px 3px 5px 3px;
  max-width: 1024px;
}

.game_info {
  display: flex;
  flex-wrap: wrap;
  width: 55%;
  text-align: center;
  margin: 0 auto;
}

.game_info.sim-thunder {
  width: 70%;
}

.game_media {
  display: flex;
  flex-direction: column;
  width: 45%;
  border-top-right-radius: 5px;
}

.game_media.sim-thunder {
  width: 30%;
}

.first_col {
  display: block;
  width: 35%;
  max-width: 185px;
  padding-right: 16px;
  margin-left: 5px;
    
}

.first_col.sim-thunder-col {
  padding-top: 7px;
}

.second_col {
  position: relative;
  flex-direction: column;
  width: 65%;
  text-align: justify;
}

.second_col h2 {
  text-align: left;
}

@media only screen and (max-width: 1024px) {

  .games_container {
    flex-direction: column;    
  }

  .game_info, .game_media {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 15px;
  }

  .main-games {
    min-height: 820px;
  }

  .main-games-pirates {
    min-height: 920px;
  }

  .game_info.sim-thunder, .game_media.sim-thunder {
    width: 100%;
    min-height: 200px;
  }
  
}

.logo-big-simthunder {
  display: block;
  background-image: url('../assets/imgs/simthunder.png') ;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  min-height: 100px;
}

.logo-big-cryptochaser {
  display: block;
  background-image: url('../assets/imgs/crypto-chaser-logo.png') ;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  min-height: 100px;
}

.logo-big-neeon {
  display: block;
  background-image: url('../assets/imgs/neeon-logo.png') ;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  min-height: 100px;
}

.pirates_icon {
  display: block;
  width: 160px;
  height: 160px;
  background-image: url('../assets/imgs/Pirates2048.png') ;
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
}

.simthunder_icon {
  display: block;
  width: 160px;
  height: 80px;
  background-image: url('../assets/imgs/simthunder.png') ;
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
}

.neeon_icon {
  display: block;
  height: 169px;
  background-image: url('../assets/imgs/neeon-logo.png') ;
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
}

.inner_slide {
  width: 300px;
  height: 300px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.pirates_slide_0 {
  background-image: url('../assets/imgs/pirates_0.png') ;
}
.pirates_slide_1 {
  background-image: url('../assets/imgs/pirates_1.png') ;
}
.pirates_slide_2 {
  background-image: url('../assets/imgs/pirates_2.png') ;
}
.pirates_slide_3 {
  background-image: url('../assets/imgs/pirates_3.png') ;
}

.neeon_slide_0 {
  background-image: url('../assets/imgs/neeonpic0.png') ;
}
.neeon_slide_1 {
  background-image: url('../assets/imgs/neeonpic1.png') ;
}
.neeon_slide_2 {
  background-image: url('../assets/imgs/neeonpic2.png') ;
}
.neeon_slide_3 {
  background-image: url('../assets/imgs/neeonpic3.png') ;
}

.game-modal .modal-dialog {
  /*min-height: 600px;*/

  min-height: 90VH;
}

.game-modal .modal-body {
  padding: 0.5em!important;
}

.modal-content-next, .modal-content-previous {

  display: block;
  position: absolute!important;
  top: 50%;
  background-color: transparent;
  border: none;
  font-size: 20px;
  /*border-radius: 4px;*/
  color: #333;
  font-weight: bolder;
 /*padding: 4px 8px 4px 8px;*/
 /*increase a bit touch area*/
  width: 30px;
  height: 30px;
  text-align: center;
}

.modal-content-next:hover, .modal-content-previous:hover {
  cursor: pointer;
}

.modal-content-next {
  right: 15px;
}

.modal-content-previous {
  left:15px;
}


.modal_img_container {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 600px;
}
.p-row {
  display: flex;
}

.social_min {
  margin: 10px 3px 3px 3px;
  width: 24px;
  height: 24px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.twitter_min {
  background-image: url('../assets/imgs/twitter.png') ;
}
.discord_min {
  background-image: url('../assets/imgs/discord.png') ;
}


.button_dapp {
  display: block;
  background-color: #f0b513;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding:6px 5px 6px 5px ;
  border-radius: 4px;
  border: 1px solid #000;
  margin-bottom:4px;
}

.button_dapp:hover {
  text-decoration: none;
  color: white;
  font-weight: bolder;
}

/*games pages boxes*/
.box.box-no-margin-top {
 margin-top: 0;
}
.testme {
  background-color: #f0b513;
}


@media screen and (max-width: 900px) {
  .box.playbox {
      display: flex;
      flex-wrap: wrap;
      margin-top:80px;
      min-height: 640px;
  }

  .playbox-main {
    min-height: 1024px;
  }

}

@media screen and (max-width: 600px) {
  .box.playbox {
    margin-top: 80px;
    min-height: 1380px;
  }
}

.box.stake {
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  width: 80%;
}

@media screen and (max-width: 600px) {
  .box.stake {
    margin-top: 80px;
    min-height: 2000px;
  }
}

.slides-container {
  width: 100%;
  margin: 0 auto;
}

.coming-soon {
  text-decoration: line-through;
}


.box-games {
  overflow-y: auto;
}

.game_video {
  width: 80%;
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
  min-width: 300px; 
  min-height: 360px;
  text-align: center;
  margin-bottom: 20px;
  /*margin-top: -1.5rem;*/
}

.video_container {
  width: 100%;
  height: 360px;
}

.video {
  
  width: 100%;
  height: 100%;
}

/*
.games_container_flex {
  display: flex;
  flex-direction: column;
}

.box-games-page {
  display: flex;
  flex-direction: column;
  margin-top:100px;
  height: 100%;
}

.game_video {
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  text-align: center;
  min-width: 300px; 
  height: 100%;
}

.video_container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer_ggdap_games {
  position: relative!important;
  bottom: 0;
  height: 180px; 
}*/

@media screen and (max-width: 1040px) {
  .main.stake {
    min-height: 1320px;/*because it will show in 2 rows after 1024*/
  }
 }

 @media screen and (max-width: 1024px) {
  .main.stake {
    min-height: 1380px;/*because it will show in 2 rows after 1024*/
  }

  .header_extra {
    font-size: 12px;
  }

  .box-games {
    margin-bottom: 15px;
  }
 }

 @media screen and (min-width: 1024px) {
  .box-games {
    margin-bottom: 0;
  }
 }

 .innerBox-container {
   display: flex;
   flex-direction: row;
   width: 80%;
   margin: 0 auto;
 }

 .box-frame-link, .box-frame-title {
  color: black;
  font-family: 'RobotoMono',Arial,sans-serif;
  font-size: 20px;
  font-weight:400;
 }
 .box a:hover {
   text-decoration: none!important;
 }

.box-frame-title span, .box-frame-link span{
   /*margin: 0 auto;*/
   font-weight: bold;
 }

.full_width {
  width:100%;
}

/*smaller font*/
.innerBoxPlay .box-frame-title  .smaller-title {
  font-size: 15px!important;
  font-weight: 400!important;
  color: #444;
}


.box.box-about {
  background-color: #fff;
  width: 60%;
  display: flex;
  flex-direction: column;
  border: 2px solid #000;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 180px;
  min-height: 50%;
}

.box.box-about .inner-box-about {
  margin-top: 2rem;
  height: 100%;
  margin: 25px 20px 20px 20px;
  text-align: justify;
}

.about-box-main {
  min-height: 900px;
}

@media screen and (max-width: 900px) {


  .about-box-main {
    min-height: 1024px;
  }

}


@media screen and (max-width: 1024px) {

  .about-box-main {
    min-height: 960px;
  }

  .box.box-about {
   
    margin-bottom: 5rem;
    position: relative;
  }

  .box.box-about .inner-box-about { 
    font-size: 0.98rem;
  }
}

@media screen and (max-width: 600px) {
  .box.box-about {
    min-height:960px;
  }

  .box.box-about .inner-box-about { 
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 400px) {
 
  .box.box-about .inner-box-about { 
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 320px) {
 
  .box.box-about .inner-box-about { 
    font-size: 0.70rem;
  }
}

@media screen and (max-width: 860px) {

  .about-box-main {
    min-height: 1024px;
  }

}

.inner-box-changelog {
 text-align: center;
}

#inner-box-changelog h4 {
  text-align: center;
  margin-bottom: 2.2rem;
}


.btn.project-info-btn {
  background-color: #f0b513;
  color: white;
  margin-top: 10px;
}
.btn.project-info-btn:hover {
  cursor: pointer;
  color: black;
}

.highlight-span{
  font-weight: bold!important;
}

.smaller_td {
  font-size:11px;
  word-break: break-all;
}

.trBorderedDown {
  border-bottom:1px solid #000;
}

.tdNoBorderLeft {
  border-left: none;
}

.tdFullBordered {
  border:1px solid #000;
}

.noBorders {
  border:none;
}


@media only screen and (max-width: 1320px) {
  .footer_ggdap {
      position:relative;
  }
}

.td-center-text {
  display: table-cell;
  vertical-align: middle;
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
}



@media only screen and (max-width: 600px) {
  .games_container {
    width: 100%;
  }

  .first_col {
    width: 100%;
    margin: 0 auto;     
  }
  
  .second_col {
    width: 100%;
    margin: 0 auto;
    padding: 3px;
  }

  .second_col h2 {
    text-align: center;
  }
}

@media screen and (max-width: 600px) {

  .alert-component {
    max-width:420px;
  }

  .alert-component .alert-heading.h4 {
    font-size: 1.4rem;
  }
  .alert-component .alert_body {
    font-size: 0.80rem;
  }
}

@media screen and (max-width: 400px) {

  .alert-component {
    max-width:320px;
  }

  .alert-component .alert-heading.h4 {
    font-size: 1.2rem;
  }
  .alert-component .alert_body {
    font-size: 0.75rem;
  }
}