
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  text-align: center;
}

.main {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-image: url('../assets/imgs/background.png') ;
  background-position: -6vw bottom;
  background-repeat: no-repeat;
  background-size: 65%;
  min-height: 640px;
}


@media only screen and (max-width: 1024px) {

  .main {
    background-size: 75%;
  }
}

@media only screen and (max-width: 768px) {

  .main {
    background-size: 80%;
  }
}

@media only screen and (max-width: 640px) {

  .main {
    background-size: 85%;
  }
}

@media only screen and (max-width: 512px) {

  .main {
    background-size: 90%;
  }
}

@media only screen and (max-width: 480px) {

  .main {
    background-size: 95%;
  }
}

@media only screen and (max-width: 256px) {

  .main {
    background-size: 100%;
  }
}

@media only screen and (max-width: 550px) {
  .main_kong {
    background-size:300px 400px;
    width: 300px;
    height: 400px;
    bottom: -6.2rem;
  }

  .main.stake {
    background-size:490px 67px;
    background-position: center 15%;
  }

  
}

.main.stake {
  background-image: url('../assets/imgs/clouds.png') ;
  background-position: right 15%;
  background-repeat: no-repeat;
  background-size:50vw;
}

.main.invest {
  /*background-image: url('../assets/imgs/mountain.png') ;
  background-position: left 60%;
  background-repeat: no-repeat;
  background-size:500px 400px;*/
  width: 100%;
  height: 100%;
}

.mountain_div {
  background-image: url('../assets/imgs/mountain.png') ;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size:500px 400px;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -3rem;

}

@media only screen and (max-width: 550px) {
  .mountain_div {
   
    background-size:400px 300px;
    width: 300px;
    height: 400px;
    bottom: -6.2rem;
  }
}

.heli_1 {
  background-image: url('../assets/imgs/heli_1.svg') ;
  background-position: 6% 5%;
  background-repeat: no-repeat;
  background-size:90px 90px;
  width: 200px;
  height: 200px;
  position: absolute;
  top:35%;
  left: 2%;
  /*animation: fly_circle 16s linear infinite;*/
  z-index: 350;
}

.plane_2 {
  background-image: url('../assets/imgs/plane_2.svg') ;
  background-position: 3% 73%;
  background-repeat: no-repeat;
  background-size:120px 100px;
  width: 100%;
  height: 100%;
  position: absolute;
  /*animation: fly_backfourth 14s linear infinite;*/
  z-index: 10;
}

.cloud_div {
  background-image: url('../assets/imgs/clouds.png') ;
  background-position: left 15%;
  background-repeat: no-repeat;
  background-size: 345px 63px;
  width:345px;
  height: 63px;
  z-index: 300;
  position: absolute;
  top: 50%;
}

@keyframes fly_circle{
  0%{
    transform:rotate(0deg)
              translate(-80px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(360deg)
              translate(-80px)
              rotate(-360deg);
  }
}

@keyframes fly_backfourth {
  0% {
    left: 0;
  }
  50% {
    left: 220px;
  }
  100% {
    left: 0;
  }
}

/*sun*/
/*.main::after {
  content:"";
  width:450px;
  height:450px;
  background-color:orange;
  border-radius:100%;
  position: absolute;
  left: 4rem;
  bottom:15%;
  z-index:-1;
  
}*/

.main.underconstruction::after {
  content:"";
  background: transparent;
}

.main::after:hover {
  transition: transform .3s ease-out;
  transform: translate(20px, -55px);
}

.main.stake::after {
  background-color:transparent;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner-outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0,0,0,0.5); /*dim the background*/
}

.spinner {
  position: absolute;
  left: 47.5%;
  top: 45%;
  height:60px;
  width:60px;
  margin:0px auto;
  -webkit-animation: rotation .6s infinite linear;
  -moz-animation: rotation .6s infinite linear;
  -o-animation: rotation .6s infinite linear;
  animation: rotation .6s infinite linear;
  border-left:6px solid rgba(0,174,239,.15);
  border-right:6px solid rgba(0,174,239,.15);
  border-bottom:6px solid rgba(0,174,239,.15);
  border-top:6px solid rgba(250,250,17,.8);
  border-radius:100%;
}

.spinner-msg {
  color: rgba(250,250,17,1);
  font-weight: bold;
  padding-top: 100px;
}

@-webkit-keyframes rotation {
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
  from {-moz-transform: rotate(0deg);}
  to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
  from {-o-transform: rotate(0deg);}
  to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
  from {transform: rotate(0deg);}
  to {transform: rotate(359deg);}
}

.header_extra {
  display: block;
  /*border-radius: 4px;*/
  color: #f0b513;
  font-weight: normal;
  text-align: justify;
  border: 1px solid #f0b513;
  background-color: lightgoldenrodyellow;
  max-width: 99%;
  margin: 0 auto;
  margin-top: 75px;
  font-size: 13px;
  padding: 2px;
  max-width: 72%;
}
