@font-face {
  font-family: 'PixelFont';
  src:  url('../assets/fonts/17593.woff') format('woff')
}

@font-face {
  font-family: 'RobotoMono';
  src:  url('../assets/fonts/RobotoMono-Regular.woff') format('woff')
}

body {

  margin: 0;
  font-family: 'RobotoMono',Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef!important;
}

html {
  font-family: 'RobotoMono',Arial,sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

