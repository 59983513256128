@keyframes smooth_fade {
    0% { opacity: 1;}
    10% { opacity: 1;}
    20% { opacity: 1;}
    30% { opacity: 1;}
    50% { opacity: 0.8;}
    60% { opacity: 0.6;}
    80% { opacity: 0.5;}
    90% { opacity: 0.3;}
    95% { opacity: 0.1;}
    100% { opacity: 0; }
}
@-webkit-keyframes smooth_fade {
    0%   { opacity: 1; }
    10% { opacity: 1;}
    20% { opacity: 1;}
    30% { opacity: 1;}
    50% { opacity: 0.8;}
    60% { opacity: 0.6;}
    80% { opacity: 0.5;}
    90% { opacity: 0.3;}
    95% { opacity: 0.1;}
    100% { opacity: 0; }
}

.alert_warning {
    /*position: fixed;*/
    margin: 0 auto;
    top:0;
    /*left: 0;*/
    width: 80%;
    z-index: 10012;
    min-height: 100px;
    animation: smooth_fade 12s ease-out;
    -webkit-animation: smooth_fade 12s ease-out;

    font-family: 'RobotoMono',Arial,sans-serif;
}

.alert_body {
    font-size: 0.85rem;
}

.alert_warning .alert-heading h4 {
    font-size: 0.3rem!important;
}

.generic_alert {
    z-index: 10012;
    width:30rem;
    height: 10rem;
    margin: 0 auto;
    top:5rem;
}

.generic_alert .alert-heading h4 {
    font-size: 0.3rem!important;
}
